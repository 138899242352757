<template>
  <!-- プライバシーポリシー・利用規約への同意 -->
  <div class="account-opening">
    <h2 class="text-cmain text-center">プライバシーポリシー・利用規約</h2>
    <p class="text-csub">
      以下の「MYPLRプライバシーポリシー」「情報口座管理サービス利用規約」をよくお読みいただき、
      同意いただける場合は、チェックを入れて次へお進みください。
      本同意事項に同意いただけない場合は、本サービスをご利用いただけません。
    </p>
    <!-- 説明動画 -->
    <b-card no-body class="mb-5 py-3">
      <b-card-body>
        <p>必要に応じてご視聴ください。</p>
        <b-form-row>
          <b-col cols="6" sm="4" md="3">
            <b-button
              variant="outline-csub"
              class="w-100 mb-3"
              href="https://youtu.be/9_lPtX7AMeI"
              target="_blank"
            >
              PLR説明動画
            </b-button>
          </b-col>
          <b-col> ＰＬＲおよびＭＹＰＬＲの説明動画です。 </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="6" sm="4" md="3">
            <b-button
              variant="outline-csub"
              class="w-100 mb-3"
              href="https://youtu.be/VCru7INa5kc"
              target="_blank"
            >
              口座開設手順動画
            </b-button>
          </b-col>
          <b-col> ＭＹＰＬＲ口座開設の説明動画です。 </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="6" sm="4" md="3">
            <b-button
              variant="outline-csub"
              class="w-100"
              href="https://youtu.be/A_hVtXk2ovs"
              target="_blank"
            >
              規約等説明動画
            </b-button>
          </b-col>
          <b-col>
            ＭＹＰＬＲ情報口座サービス運用および規約についての説明です。
          </b-col>
        </b-form-row>
      </b-card-body>
    </b-card>
    <b-card no-body class="mb-5 py-3">
      <!-- プライバシーポリシー同意 -->
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          MYPLRプライバシーポリシー
        </b-card-title>
        <p class="text-csub">
          <b-icon icon="info-circle-fill"></b-icon
          >最後までスクロールしてお読みください。
        </p>
        <b-card no-body class="agreement-content">
          <b-card-body class="scroll-element" ref="policy">
            <p>
              本ポリシーは，一般社団法人データビリティコンソーシアム（以下「コンソーシアム」といいます。）が，本サービス（第2条で定義します。）を提供するに際して，収集する個人情報（第2条で定義します。）の取扱いを定めます。
            </p>
            <section>
              <h4>第1条（適用関係）</h4>
              <ol>
                <li>本ポリシーは，本サービスの利用に関し適用されます。</li>
                <li>
                  コンソーシアムが，コンソーシアムウェブサイト上に掲載するプライバシーポリシーその他の個人情報保護方針又は本サービスに関する利用規約などにおける個人情報の取扱いに関する規定と，本ポリシーが矛盾又は抵触する場合には，本ポリシーが優先します。
                </li>
                <li>
                  本サービスの連携サービスには，本ポリシーは適用されません。連携サービスにおける個人情報の取扱いは，そのサービスの提供事業者が定めるプライバシーポリシーなどを参照してください。
                </li>
              </ol>
            </section>
            <section>
              <h4>第2条（定義）</h4>
              <p>本ポリシーでは，次の各用語は，次の意味を有します。</p>
              <b-table-simple bordered>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th class="width20">用語</b-th>
                    <b-th>定義</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>本サービス</b-th>
                    <b-td>
                      コンソーシアムが行う情報口座管理サービス及びＰＬＲ基盤を介した情報連携サービス
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>連携サービス</b-th>
                    <b-td>
                      本サービスと連携するコンソーシアム以外の者が提供するサービス
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>サービス利用者</b-th>
                    <b-td>
                      情報口座管理サービス利用規約に基づいて本サービス利用契約を締結し，本サービスを利用する個人
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>ＰＬＲデータ</b-th>
                    <b-td>
                      コンソーシアムが運営する本サービスにおいてコンソーシアムの会員間においてＰＬＲ基盤を介して提供されるデータ
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>データ提供者</b-th>
                    <b-td> 本サービスを通じてＰＬＲデータを提供する者 </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>データ利用者</b-th>
                    <b-td>
                      本サービスを通じてＰＬＲデータをデータ提供者から提供を受ける者
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>個人情報保護法</b-th>
                    <b-td>
                      個人情報の保護に関する法律（平成15年法律第57号）
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>法令</b-th>
                    <b-td>法，政令，規則，基準及びガイドラインなど</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>個人情報</b-th>
                    <b-td>個人情報保護法2条1項の「個人情報」</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>個人データ</b-th>
                    <b-td>個人情報保護法2条6項の「個人データ」</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>保有個人データ</b-th>
                    <b-td>個人情報保護法2条7項の「保有個人データ」</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </section>
            <section>
              <h4>第3条（利用目的）</h4>
              <p>
                コンソーシアムは，次の個人情報を取得し，次の利用目的の範囲で，個人情報を取り扱います。
              </p>
              <b-table-simple bordered caption-top class="custom-list">
                <caption>
                  <b>【個人情報】</b>
                </caption>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>取得する情報</b-th>
                    <b-th>利用目的</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>
                      氏名，住所，電話番号，性別，生年月日，メールアドレス，大学生協組合員番号，顔認証データ（顔写真データを含む。），サービス利用者を特定する情報口座情報（ＰＬＲ−ＩＤ）
                    </b-td>
                    <b-td>
                      <ol>
                        <li>
                          <span>①</span>
                          情報連携サービスにおける情報口座の開設にあたってサービス利用者の本人確認を行う目的
                        </li>
                        <li>
                          <span>②</span>
                          情報連携サービスにおいて，データ提供者からデータ利用者にＰＬＲデータが開示される際に，
                          ＰＬＲデータの重複がないか否かを確認する目的
                        </li>
                        <li>
                          <span>③</span>
                          情報連携サービスにおいて，データ提供者からデータ利用者に個人情報が含まれるＰＬＲデータを提供するにあたり，
                          利用目的を通知し，第三者提供の同意を取得するために，対象となるサービス利用者を特定する目的
                        </li>
                        <li>
                          <span>④</span>
                          情報連携サービスにおいて，サービス利用者に対して個人情報が含まれるＰＬＲデータを提供したことの謝礼として与えられる経済的利益の提供又はサービス提供の仲介をするために，対象となるサービス利用者を特定する目的
                        </li>
                        <li>
                          <span>⑤</span>
                          サービス利用者の個人情報が含まれるＰＬＲデータの管理をサポートするためにデータ提供者が付与するＩＤと，本サービスにおいてコンソーシアムが付与する情報口座ＩＤを紐付ける目的
                        </li>
                        <li>
                          <span>⑥</span>
                          情報連携サービスにおいてＰＬＲデータの量あるいは当該データの管理者その他情報連携サービスにおいて必要な情報を検索する目的
                        </li>
                        <li>
                          <span>⑦</span>
                          サービス利用者がデータ利用者に対して保有個人データあるいは保有個人情報の開示，訂正・追加・削除，利用停止・消去若しくは第三者提供の停止の請求を行う際に，データ利用者の求めに応じて請求者であるサービス利用者の当該保有個人データあるいは当該保有個人情報を特定する目的（データ利用者に対してサービス利用者の個人情報を提供する目的も含みます。）
                        </li>
                        <li>
                          <span>⑧</span>
                          本サービスについてのアンケート等による利用者意識の調査研究目的
                        </li>
                        <li>
                          <span>⑨</span>
                          サービス利用者に対して，ＰＬＲ基盤の活動状況，ＰＬＲデータの利用による成果，ＰＬＲデータの利用を伴う研究参加の募集や説明会開催等に関する案内を送付する目的
                        </li>
                        <li>
                          <span>⑩</span>
                          その他前各号を実現するために必要又は関連する目的
                        </li>
                      </ol>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      以下の本人確認書類に記載された個人情報
                      <ol>
                        <li><span>①</span>運転免許証</li>
                        <li><span>②</span>運転経歴証（写真付きのもの）</li>
                        <li><span>③</span>旅券（パスポート）</li>
                        <li><span>④</span>在留カード</li>
                        <li><span>⑤</span>特別永住者証明書</li>
                        <li>
                          <span>⑥</span>
                          外国人登録証明書（在留の資格が特別永住者のものに限ります。）
                        </li>
                        <li><span>⑦</span>住民票</li>
                        <li><span>⑧</span>運転経歴証（写真なしのもの）</li>
                        <li>
                          <span>⑨</span>
                          健康保険，国民健康保険又は船員保険等の被保険者証
                        </li>
                        <li><span>⑩</span>共済組合員証</li>
                        <li><span>⑪</span>年金手帳</li>
                        <li>
                          <span>⑫</span>
                          国民年金，厚生年金保険又は船員保険にかかる年金証書
                        </li>
                        <li><span>⑬</span>共済年金又は恩給等の証書</li>
                        <li><span>⑭</span>学生証</li>
                        <li><span>⑮</span>マイナンバーカード（表面）</li>
                      </ol>
                    </b-td>
                    <b-td>
                      情報口座の開設にあたってサービス利用者の本人確認を行う目的
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </section>
            <section>
              <h4>第4条（第三者提供）</h4>
              <p>
                コンソーシアムは，法令に基づく場合を除き，第三者に対して個人データを提供しません。法令に基づき開示するときは，個人情報保護法その他適用法令を遵守します。
              </p>
            </section>
            <section>
              <h4>第5条（業務委託）</h4>
              <p>
                コンソーシアムは，利用目的の達成に必要な範囲内において，個人情報（検索することができるように体系的に構成されたものに限ります。）の全部又は一部の取扱いを第三者に委託することがあります。コンソーシアムは，委託先が，個人情報を適切かつ安全に管理するように監督します。
              </p>
            </section>
            <section>
              <h4>第6条（共同利用）</h4>
              <p>
                コンソーシアムは，グループ企業その他第三者と共同して事業活動を行うとき，その活動に必要な個人情報（検索することができるように体系的に構成されたものに限ります。）を共同利用することがあります。このとき，あらかじめ利用目的，データ項目，利用者の範囲及び管理責任者を明確にし，ご本人に通知又は公表します。また，コンソーシアムは，共同利用者に，個人情報を適切かつ安全に管理することを確約させます。
              </p>
            </section>
            <section>
              <h4>第7条（保有個人データの開示・訂正などの権利）</h4>
              <ol>
                <li>
                  コンソーシアムが管理する保有個人データについて，ご本人又はその代理人から利用目的の通知の請求又は開示，訂正・追加・削除，利用停止・消去若しくは第三者提供の停止の請求（以下「開示等の請求」といいます。）をされる場合は，第９条のお問い合わせ窓口に以下の情報をご提供ください。
                  <div class="list-bracket list-bracket-inside">
                    <ol>
                      <li>氏名</li>
                      <li>住所又は居所</li>
                      <li>電話番号</li>
                      <li>
                        法定代理人による請求の場合，法定代理人の氏名，住所又は居所，電話番号
                      </li>
                      <li>
                        開示等の請求される保有個人情報を特定するに足りる情報
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  前項の請求がご本人あるいはその法定代理人からの請求であることをコンソーシアムが確認できた後，コンソーシアムは個人情報保護法の定めに基づき遅滞なく保有個人データの開示，訂正・追加・削除，利用停止・消去又は第三者提供の停止に応じます。ただし，以下のいずれかに該当する場合，コンソーシアムは開示，訂正・追加・削除，利用停止・消去又は第三者提供に応じる義務を負いません。
                  <div class="list-bracket list-bracket-inside">
                    <ol>
                      <li>
                        開示等の請求の対象となる保有個人データが存在しない場合
                      </li>
                      <li>
                        個人情報保護法その他の法令により，コンソーシアムが開示，訂正・追加・削除，利用停止・消去又は第三者提供に応じる義務を負わない場合
                      </li>
                      <li>
                        開示，訂正・追加・削除，利用停止・消去又は第三者提供に多額の費用が要する場合その他これらの対応を行うことが困難な場合であって，本人の権利利益を保護するため必要なこれに代わる措置をとる場合
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  第１項の請求に基づいてコンソーシアムが保有個人データを開示するにあたり，１件あたり，１０００円の手数料を頂戴しておりますので，ご了承ください。
                </li>
              </ol>
            </section>
            <section>
              <h4>第8条（本ポリシーの変更）</h4>
              <ol>
                <li>
                  コンソーシアムは，個人情報の取扱いに関する運用状況を適宜見直し，継続的な改善に努めるものとし，必要に応じて，本ポリシーを変更することがあります。
                </li>
                <li>
                  前項にもかかわらず，法令上，ご本人の同意が必要となるような内容の変更を行うときは，別途コンソーシアムが定める方法により，ご本人の同意を取得します。
                </li>
                <li>
                  前２項の変更後の本ポリシーについては，本サービス上又はコンソーシアムのウェブサイトでの掲示その他分かりやすい方法により告知します。
                </li>
              </ol>
            </section>
            <section>
              <h4>第9条（お問い合わせ）</h4>
              <p>
                コンソーシアムによる個人情報の取扱いに関するご意見，ご質問，ご要望その他お問い合わせは，下記の窓口までお願いします。
              </p>
              <p class="no-indent">
                〒567-0047<br />
                大阪府茨木市美穂ヶ丘8-1 大阪大学産業科学研究所内<br />
                (一財)大阪大学産業科学研究協会 気付<br />
                一般社団法人 データビリティコンソーシアム<br />
                MYPLRお問合せ係
              </p>
              <p class="no-indent">
                連絡先:<br />
                電話: 06-6155-5035<br />
                メール: myplr@cds.or.jp
              </p>
            </section>
            <section>
              <h4>附則</h4>
              <p class="no-indent">
                このポリシーは，2022年12月22日から改定実施します。
              </p>
            </section>
          </b-card-body>
          <b-card-footer footer-bg-variant="csub-light">
            <b-form-checkbox
              v-model="policyAgreement"
              class="text-center box-large"
              name="agreement-policy"
              v-bind:disabled="policyDisabled"
            >
              <span class="text-csub">MYPLRプライバシーポリシーに同意する</span>
            </b-form-checkbox>
          </b-card-footer>
        </b-card>
      </b-card-body>
      <!-- 利用規約同意 -->
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          情報口座管理サービス利用規約
        </b-card-title>
        <p class="text-csub">
          <b-icon icon="info-circle-fill"></b-icon
          >最後までスクロールしてお読みください。
        </p>
        <b-card no-body class="agreement-content">
          <b-card-body class="scroll-element" ref="terms">
            <p>
              情報口座管理サービス利用規約（以下「本規約」といいます。）は，一般社団法人データビリティコンソーシアム（以下「コンソーシアム」といいます。）が運営する情報口座管理サービス（以下「本サービス」といいます。）の利用規約です。本規約には，コンソーシアムとサービス利用者との権利義務関係が定められています。サービス利用者が本サービスを利用する際には，本規約に同意したものとみなします。
            </p>
            <section>
              <h4>第1条（定義）</h4>
              <p>本規約における各用語の定義は，以下のとおりとします。</p>
              <b-table-simple bordered class="custom-list">
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th class="width20">用語</b-th>
                    <b-th>意義</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th>本サービス利用契約</b-th>
                    <b-td>
                      本規約に基づいてコンソーシアムとサービス利用者の間で成立する本サービスの利用に関する契約
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>サービス利用者</b-th>
                    <b-td>
                      本サービス利用契約に基づいて本サービスを利用する者
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>利用者端末</b-th>
                    <b-td>
                      本サービスにおいてサービス利用者が使用するコンピュータ，スマートフォンなどの端末
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>個人情報保護法等</b-th>
                    <b-td>
                      個人情報の保護に関する法律及び独立行政法人等の保有する個人情報の保護に関する法律
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>ＰＬＲデータ</b-th>
                    <b-td>
                      コンソーシアムが運営する情報連携サービスにおいてコンソーシアムの会員間においてＰＬＲ基盤を介して提供されるデータ
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>MYPLRコイン</b-th>
                    <b-td>
                      情報連携サービスを通じて，サービス利用者の個人情報が含まれるＰＬＲデータ等が提供されたことに対する謝礼として交付されるコイン
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </section>
            <section>
              <h4>第2条（本サービスの内容）</h4>
              <ol>
                <li>
                  本サービスは，サービス利用者が所有する利用者端末において以下の機能を提供することを目的としたサービスです。
                  <div class="list-bracket list-bracket-inside">
                    <ol>
                      <li>
                        コンソーシアムが運営するコンソーシアムの会員間における情報連携サービス（以下「情報連携サービス」という。）を通じて利活用されるサービス利用者の個人情報が含まれるＰＬＲデータの管理のサポート
                      </li>
                      <li>
                        情報連携サービスを通じて，サービス利用者の個人情報が含まれるＰＬＲデータが第三者に提供される場合における個人情報保護法等における利用目的の通知あるいは第三者提供の同意等の取得
                      </li>
                      <li>
                        情報連携サービスを通じて，サービス利用者の個人情報が含まれるＰＬＲデータが第三者に提供されたことの謝礼（ＭＹＰＬＲコインを含むがこれに限られない。以下，同じ。）としてサービス利用者に与えられる経済的利益の提供及び管理若しくはサービス提供の仲介
                      </li>
                      <li>
                        その他前各号の機能を実行するために必要又は関連する機能
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  本サービスにおいて，コンソーシアムが取得するサービス利用者の個人情報及びその利用目的は，ＭＹＰＬＲプライバシーポリシー（https://www.cds.or.jp/datahand/files/myplr_policy.pdf）に記載するとおりであり，その利用目的の範囲内でサービス利用者の個人情報を利用します。
                </li>
                <li>
                  本サービスにおいて，コンソーシアムは，前項の個人情報を除き，情報連携サービスで利活用がなされるサービス利用者の個人情報を取得していないことをサービス利用者は確認し，了承したものとします。
                </li>
              </ol>
            </section>
            <section>
              <h4>第3条（本サービスを提供する対象地域）</h4>
              <p>本サービスの利用は，日本国内での利用に限られます。</p>
            </section>
            <section>
              <h4>第4条（契約の申込み及び登録）</h4>
              <ol>
                <li>
                  本サービスの利用を希望する者（以下「申込者」といいます。）は，本規約を遵守することに同意の上，コンソーシアム所定の方法でコンソーシアムが定める一定の情報（以下「登録情報」といいます。）をコンソーシアムに提供することで，本サービスの利用の申込みを行うことができます。
                </li>
                <li>
                  コンソーシアムは，コンソーシアムの基準に従って申込者の本サービスの利用の可否を判断して，コンソーシアムがその利用を可と判断した場合に，その旨を申込者に通知します。コンソーシアムがその通知を発信した時点をもって，本サービス利用契約がサービス利用者とコンソーシアムの間に成立し，サービス利用者は本規約に従って本サービスを利用することができます。
                </li>
                <li>
                  前項の規定に基づいて申込者の本サービスの利用を否と判断した場合であっても，コンソーシアムはその理由について開示する義務を負いません。
                </li>
                <li>
                  申込者が未成年者である場合，親権者の同意に基づいて本サービスの利用の申込みを行ったものとみなします。
                </li>
              </ol>
            </section>
            <section>
              <h4>第5条（登録情報の変更）</h4>
              <ol>
                <li>
                  サービス利用者の登録情報に変更があった場合，サービス利用者は，コンソーシアム所定の方法で速やかにその旨通知・連絡をします。
                </li>
                <li>
                  サービス利用者が前項の変更事項の通知・連絡を怠ったことによって，当該サービス利用者に損害が発生したとしても，コンソーシアムは一切責任を負いません。
                </li>
              </ol>
            </section>
            <section>
              <h4>第6条（ＩＤ・パスワード等の管理）</h4>
              <ol>
                <li>
                  サービス利用者は，本サービスを利用する際の認証に使用するＩＤ及びパスワード等を，自己の責任において，適切に管理及び保管し，これを第三者（本サービスの他のサービス利用者を含みます。）に使用をさせ，又は貸与，譲渡，名義変更，売買等をすることは一切できません。
                </li>
                <li>
                  前項のＩＤ及びパスワード等を認証に用いて本サービスが利用された場合，当該利用は，当該ＩＤ及びパスワード等を付与されたサービス利用者により行われたものとみなされ，当該ＩＤ及びパスワード等の管理不十分，使用上の過誤，第三者の使用等によってサービス利用者に生じた損害についてコンソーシアムは一切責任を負いません。
                </li>
              </ol>
            </section>
            <section>
              <h4>第7条（本サービスの提供方法とその環境構築）</h4>
              <p>
                本サービスの利用にあたり，サービス利用者は，自らの責任と費用において本サービスを利用するために必要な機器，通信環境等を準備して本サービスを利用します。
              </p>
            </section>
            <section>
              <h4>第8条（料金及び支払方法）</h4>
              <p>サービス利用者は無償で本サービスを利用できるものとします。</p>
            </section>
            <section>
              <h4>第9条（禁止事項）</h4>
              <p>
                サービス利用者は，以下の各行為又はそのおそれのある行為をしてはなりません。
              </p>
              <div class="list-bracket">
                <ol>
                  <li>法令に違反する行為又は犯罪行為に関連する行為</li>
                  <li>公序良俗に反する行為</li>
                  <li>
                    コンソーシアム，本サービスの他のサービス利用者又はその他第三者の知的財産権，肖像権，プライバシーの権利，名誉，その他の権利又は利益を侵害する行為
                  </li>
                  <li>他人になりすまして本サービスを利用する行為</li>
                  <li>
                    本サービスの他のサービス利用者のＩＤ及びパスワードを利用する行為
                  </li>
                  <li>
                    コンソーシアムのネットワーク又はシステム等への不正アクセス行為
                  </li>
                  <li>
                    本サービスのネットワーク又はシステム等に過度の負荷をかける行為
                  </li>
                  <li>本サービスの運営を妨害する行為</li>
                  <li>反社会的勢力等への利益供与</li>
                  <li>
                    その他本サービスの運営にあたりコンソーシアムが不適切と判断した行為
                  </li>
                </ol>
              </div>
            </section>
            <section>
              <h4>第10条（権利の帰属及び使用許諾）</h4>
              <p>
                本サービスに関連する著作権（著作権法第２７条及び第２８条の権利を含みます。），商標権，特許権等の一切の知的財産権は，コンソーシアム又はコンソーシアムにその利用あるいは実施許諾をしている者に帰属しており，本契約に基づく本サービスの使用許諾は，本サービスに関するコンソーシアム又はコンソーシアムに利用あるいは実施許諾している者の知的財産権の利用あるいは実施許諾を意味するものではありません。
              </p>
            </section>
            <section>
              <h4>第11条（個人情報の取扱い）</h4>
              <p>
                本サービスに関連して，コンソーシアムがサービス利用者の個人情報を取得した場合，個人情報保護法及び関連するガイドライン等を遵守し，コンソーシアムが別途定める「MＹＰＬＲプライバシーポリシー」（https://www.cds.or.jp/datahand/files/myplr_policy.pdf）に従って個人情報を取り扱うものとし，サービス利用者は，このプライバシーポリシーに従ってコンソーシアムが個人情報を取り扱うことについて同意するものとします。
              </p>
            </section>
            <section>
              <h4>第12条（自己責任）</h4>
              <p>
                サービス利用者が，本サービスの利用に関連して，第三者に損害を与えた場合又は第三者との間で紛争が生じた場合，サービス利用者は自己の責任と費用において解決します。
              </p>
            </section>
            <section>
              <h4>第13条（免責）</h4>
              <ol>
                <li>
                  コンソーシアムは，本サービスの内容及び本サービスを通じてサービス利用者が得る成果等について，その正確性，安全性，有効性（本サービスの利用によりサービス利用者の目的を達成できることを含む。），合法性，最新性，第三者の知的財産権その他の権利利益を侵害しないことについて一切保証しません。
                </li>
                <li>
                  コンソーシアムは，本サービスが中断されず，又はエラーなしで運営されること，若しくは欠陥が修正されることについて，いかなる保証も致しません。
                </li>
                <li>
                  コンソーシアムは，サービス利用者が使用する利用者端末と本サービスに互換性があることを保証しません。
                </li>
              </ol>
            </section>
            <section>
              <h4>第14条（損害賠償）</h4>
              <ol>
                <li>
                  本サービスに起因又は関連してサービス利用者に生じたあらゆる損害について，コンソーシアムは一切の責任を負いません。ただし，本サービスに関するコンソーシアムとサービス利用者との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，本条項は適用されないものとします。
                </li>
                <li>
                  本条第１項ただし書に定める場合であっても，コンソーシアムは，コンソーシアムの過失（重過失を除きます。）による債務不履行又は不法行為によりサービス利用者に生じた損害のうち特別な事情から生じた損害（コンソーシアム又はサービス利用者が損害発生につき予見すべき場合を含みます。）について一切の責任を負いません。
                </li>
                <li>
                  サービス利用者が本規約に定める事項に違反したことにより，コンソーシアムが損害を被った場合，コンソーシアムが当該サービス利用者との本サービス利用契約を解除したか否かに関わらず，当該サービス利用者はコンソーシアムに対して全ての損害を賠償する責任を負うものとします。
                </li>
                <li>
                  コンソーシアムが，サービス利用者と第三者との紛争，その他サービス利用者の責に帰すべき事由に起因して費用（弁護士費用，証人費用，証拠収集費用及びその他訴訟遂行上の合理的費用を含む。）を負担することになる場合，コンソーシアムに現実に負担が生じる前であっても，その費用をサービス利用者は支払わなければならないものとします。
                </li>
              </ol>
            </section>
            <section>
              <h4>第15条（本サービスの停止又は中断）</h4>
              <ol>
                <li>
                  コンソーシアムは，以下のいずれかに該当する場合には，サービス利用者に事前に通知又は連絡することなく，本サービスの全部又は一部の提供を一時的に停止又は中断することができるものとします。
                  <div class="list-bracket list-bracket-inside">
                    <ol>
                      <li>
                        サーバ，通信回線その他の本サービスのための設備の故障，障害の発生又はその他の事由により本サービスの提供ができなくなった場合
                      </li>
                      <li>
                        本サービスのシステムの保守，点検，修理，変更を定期的又は緊急で行う場合
                      </li>
                      <li>
                        火災，停電などにより本サービスの提供ができなくなった場合
                      </li>
                      <li>
                        地震，噴火，洪水，津波などの天災により本サービスの提供ができなくなった場合
                      </li>
                      <li>
                        戦争，変乱，暴動，騒乱，労働争議，疫病の蔓延等その他不可抗力により本サービスの提供ができなくなった場合
                      </li>
                      <li>
                        法令による規制，司法命令等の適用により本サービスの提供ができなくなった場合
                      </li>
                      <li>
                        その他，運用上，技術上，コンソーシアムが本サービスの一時的な停止又は中断が必要と判断した場合
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  コンソーシアムは，前項に定める本サービスの全部又は一部の一時的な停止又は中断によりサービス利用者に損害が生じたとしても，その損害について，一切責任を負わないものとします。
                </li>
              </ol>
            </section>
            <section>
              <h4>第16条（本サービスの内容の変更）</h4>
              <ol>
                <li>
                  コンソーシアムは，いつでも本サービスの内容の全部又は一部を変更し，又は提供を終了することができます。
                </li>
                <li>
                  コンソーシアムが前項の措置をとる場合，コンソーシアムは，サービス利用者に対して，ＳＭＳの送信，本サービスのマイページへの掲載その他コンソーシアムが適当と認める方法により通知・連絡するものとします。
                </li>
                <li>
                  本条第１項に基づきコンソーシアムが行った措置に基づきサービス利用者に損害が生じたとしても，コンソーシアムは，その損害について一切責任を負いません。
                </li>
              </ol>
            </section>
            <section>
              <h4>第17条（解約）</h4>
              <p>
                コンソーシアム又はサービス利用者は，解約の予告をすることで，本サービス利用契約を解約することができます。解約の方法及び時期については別途コンソーシアムが定める方法によります。
              </p>
            </section>
            <section>
              <h4>第18条（登録抹消・解除）</h4>
              <ol>
                <li>
                  サービス利用者が，次に定める事項のいずれかに該当し，又は該当するおそれがあるとコンソーシアムが判断するときは，コンソーシアムは，事前に通知又は催告をすることなく，直ちに，当該サービス利用者に対する本サービスの全部又は一部の提供を停止し，本サービス利用契約を解除できるものとします。
                  <div class="list-bracket list-bracket-inside">
                    <ol>
                      <li>本規約のいずれかの条項に違反したとき。</li>
                      <li>登録情報に虚偽の事実又は誤りがあったとき。</li>
                      <li>
                        サービス利用者が過去にコンソーシアムが運営するサービスの利用の停止等の処分を受けていることが判明したとき。
                      </li>
                      <li>
                        サービス利用者が支払停止若しくは支払不能となり，又はサービス利用者に対し破産手続開始，民事再生手続開始若しくはこれらに類する手続の開始の申立てがあったとき。
                      </li>
                      <li>
                        コンソーシアムからの問い合わせその他の回答を求める連絡に対して，サービス利用者が３０日以上連絡・応答がないとき。
                      </li>
                      <li>
                        サービス利用者が１年間本サービスのアカウントにログインをしていないとき。
                      </li>
                      <li>
                        反社会的勢力であることが判明したとき，又は反社会的勢力となったとき，あるいはこれらの者と何らかの関係があることが明らかとなったとき。
                      </li>
                      <li>
                        その他本サービスを提供することが不適当であるとコンソーシアムが判断したとき。
                      </li>
                    </ol>
                  </div>
                </li>
                <li>
                  前項に基づき，コンソーシアムが本サービスの全部又は一部の提供を停止し，本サービス利用契約を解除したことにより，サービス利用者に損害が生じたとしても，その損害についてコンソーシアムは一切の責任を負いません。
                </li>
              </ol>
            </section>
            <section>
              <h4>第19条（本規約の変更等）</h4>
              <ol>
                <li>
                  コンソーシアムは，いつでも任意の理由により，本規約の内容の変更，追加，又は削除（以下「変更等」という。）を行うことができるものとします。
                </li>
                <li>
                  コンソーシアムは，本規約を変更等した場合，サービス利用者に対して，ＳＭＳによる送信，本サービスのマイページへの掲載その他コンソーシアムが適当と認める方法により通知・連絡を行うものとします。
                </li>
                <li>
                  前項の当該変更等の内容を通知・連絡した後，サービス利用者が本サービスを利用した場合，又は通知・連絡をした後，１ヶ月以内に登録抹消の手続を取らなかった場合には，サービス利用者は本規約の変更等の内容に同意したものとみなします。
                </li>
              </ol>
            </section>
            <section>
              <h4>第20条（通知・連絡）</h4>
              <ol>
                <li>
                  コンソーシアムからサービス利用者への通知・連絡は，ＳＭＳの送信，本サービスのマイページへの掲載その他コンソーシアムが適当と認める方法により行われるものとします。
                </li>
                <li>
                  コンソーシアムがＳＭＳを用いてサービス利用者に対して通知を行う場合には，当該ＳＭＳをコンソーシアムがサービス利用者に向けて発信した時点，あるいは本サービスのマイページへの掲載による場合は，その内容を当該管理画面上に掲載した時点をもって，サービス利用者に通知が到達したものとみなします。
                </li>
              </ol>
            </section>
            <section>
              <h4>第21条（本サービス利用契約終了後のデータの取扱い）</h4>
              <p>
                本サービス利用契約が終了した後，コンソーシアムが取得しているサービス利用者の個人情報について，コンソーシアムは削除し，その利用を行わないものとします。
              </p>
            </section>
            <section>
              <h4>第22条（権利義務移転の禁止）</h4>
              <p>
                サービス利用者は，コンソーシアムの書面による事前の承諾のない限り，本規約によって生じる権利義務若しくは本サービス利用契約の権利義務の全部又は一部を第三者に譲渡し，担保に供し，又はその他の処分をしてはならないものとします。
              </p>
            </section>
            <section>
              <h4>第23条（分離可能性）</h4>
              <p>
                本規約のいずれかの条項又はその一部が，法令等により無効又は執行不能と判断された場合であっても，本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は，継続して完全に効力を有するものとします。
              </p>
            </section>
            <section>
              <h4>第24条（存続条項）</h4>
              <p>
                本サービス利用契約が終了した後であっても，第５条第２項，第１２条，第１３条第１項，第１４条，第１８条第２項，第２１条ないし第２３条，本条，第２５条，第２６条の効力は存続するものとします。
              </p>
            </section>
            <section>
              <h4>第25条（準拠法）</h4>
              <p>
                本規約の成立，効力，履行及び解釈に関する準拠法は日本法とします。
              </p>
            </section>
            <section>
              <h4>第26条（管轄）</h4>
              <p>
                本規約に関する一切の紛争については，大阪地方裁判所を第一審の専属的合意管轄裁判所とすることを予め合意します。
              </p>
            </section>
            <section>
              <h4>附則</h4>
              <p class="no-indent">
                この規約は，2020年11月11日から実施します。<br />
                この規約は，2022年10月5日から変更実施します。<br />
              </p>
            </section>
          </b-card-body>
          <b-card-footer footer-bg-variant="csub-light">
            <b-form-checkbox
              v-model="termsAgreement"
              class="text-center box-large"
              name="agreement-terms"
              v-bind:disabled="termsDisabled"
            >
              <span class="text-csub"
                >情報口座管理サービス利用規約に同意して、MYPLR口座開設に申込む</span
              >
            </b-form-checkbox>
          </b-card-footer>
        </b-card>
      </b-card-body>
    </b-card>
    <b-form-row>
      <b-col offset="3" cols="6" offset-sm="4" sm="4">
        <b-button v-on:click="toInputForm()" variant="csub" class="w-100">
          次へ
        </b-button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      policyAgreement: false,
      policyDisabled: true,
      policyElement: null,
      termsAgreement: false,
      termsDisabled: true,
      termsElement: null,
    }
  },
  mounted() {
    this.policyElement = this.$refs.policy
    this.termsElement = this.$refs.terms
    this.policyElement.addEventListener('scroll', this.checkScrollPolicy)
    this.termsElement.addEventListener('scroll', this.checkScrollTerms)
  },
  beforeDestroy() {
    console.log('***** destroyed')
    this.policyElement.removeEventListener('scroll', this.checkScrollPolicy)
    this.termsElement.removeEventListener('scroll', this.checkScrollTerms)
  },
  methods: {
    checkScrollPolicy() {
      const clientHeight = this.policyElement.clientHeight
      const scrollHeight = this.policyElement.scrollHeight
      const scrollTop = this.policyElement.scrollTop
      console.log('cHeight,sHeight,sTop', clientHeight, scrollHeight, scrollTop)

      if (Math.abs(scrollHeight - (clientHeight + scrollTop)) < 20) {
        this.policyDisabled = false
      }
    },
    checkScrollTerms() {
      const clientHeight = this.termsElement.clientHeight
      const scrollHeight = this.termsElement.scrollHeight
      const scrollTop = this.termsElement.scrollTop
      console.log('cHeight,sHeight,sTop', clientHeight, scrollHeight, scrollTop)

      if (Math.abs(scrollHeight - (clientHeight + scrollTop)) < 20) {
        this.termsDisabled = false
      }
    },
    toInputForm() {
      if (!this.policyAgreement) {
        alert(
          'MYPLRプライバシーポリシーを最後までお読みいただき、同意にチェックを入れてから次へ進んでください。'
        )
        return
      }
      if (!this.termsAgreement) {
        alert(
          '情報口座管理サービス利用規約を最後までお読みいただき、同意にチェックを入れてから次へ進んでください。'
        )
        return
      }
      // 入力フォームへ
      if (this.$route.name == 'agreement-ekyc') {
        this.$router.push('/account-opening-ekyc/application-form')
      } else {
        this.$router.push('/account-opening/application-form')
      }
    },
  },
}
</script>

<style scoped>
.scroll-element {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.table {
  white-space: normal;
}
th.width20 {
  width: 20%;
}
/* 括弧数字用 */
.list-bracket-inside ol {
  margin-left: -1em; /* 2段目以降の場合 */
}
.list-bracket ol li {
  list-style-type: none;
  counter-increment: cnt;
  position: relative;
}
.list-bracket ol li:before {
  content: '(' counter(cnt) ')';
  display: inline-block;
  margin-left: -3.5em;
  width: 3em;
  text-align: right;
  position: absolute;
}
/* 行頭文字指定する場合 丸数字に使用 */
.custom-list ol {
  position: relative;
  padding: 0;
}
.custom-list ol li {
  list-style: none;
  list-style-position: outside;
  margin: 0;
  padding-left: 1.25em;
}
.custom-list ol li span {
  position: absolute;
  left: 0;
  margin: 0;
}
.agreement-content p {
  text-indent: 1em;
}
.agreement-content .no-indent {
  text-indent: 0em;
}
</style>
